import { ETariffVersions } from '../management/tariff';

export enum ESystemBaseDeviceSeries {
  D7000 = 'b923fe31-a23b-4731-8aaf-2e9875eb7000',
  D5000 = 'b923fe31-a23b-4731-8aaf-2e9875eb5000',
}

export enum ESystemDeviceMode {
  none = 'None',
  EMF = 'EMF',
  emptyEMF = 'EmptyEMF',
  miFare = 'MiFare',
  emMarin = 'EmMarin',
}

export enum EContactsStatus {
  configured = 'Сonfigured',
  notConfigured = 'NotConfigured',
  partiallyСonfigured = 'PartiallyСonfigured',
}

export interface IConnectionSettings {
  remoteId: number;
  host: string;
  port: string;
  login: string;
  password: string;
  adapterName: string;
}

export interface ISystemDevice {
  id: string;
  displayName: string;
}

export interface ISystemBase {
  objectName: string;
  shortName: string;
  address: string;
  companyId: string;
  repeaterNumbers: number;
  withSecurityDesk: boolean;
  globalNetPasswordDisabled: boolean;
  isDirectCall: boolean;
  isNotificationCallBack: boolean;
  isWithoutKeys: boolean;
  isContactsConfigured: EContactsStatus;
  isRedirect: boolean;
  isVideo: boolean;
  isCallback: boolean;
  isBlocked: boolean;
  isDefaultKeyNumber: boolean;
  keyNumbers: number | null;
  deviceSeriesId?: ESystemBaseDeviceSeries;
  secureKeyMode?: string;
  deviceUpdateTime?: string;
  isUpdateAllowForAllDevices?: boolean;
  fiasId: string;
  actAdm: string;
  connectorSetting: IConnectionSettings | null;
  scCallCode: string;
  deviceSeriesName: string;
  nightModeStart: string;
  nightModeEnd: string;
  globalNetAddress: string;
  isItvEnabled: boolean;
  globalNetPassword: number;
  defaultDispatcherId?: number;
  synchronizationNeeded: boolean;
}

export interface IObjectTransfer {
  code: string;
  createdAt: string;
  expireAt: string;
  id: string;
  status: string;
}

export interface IObjectTariffInfo {
  isObjectPaymentEnabled: boolean;
  isSetAdditionalCompanyPrice: boolean;
  isSetMaintenance: boolean;
  isSetObjectTariffDiscount: boolean;
  isSetTariffAdditionalCompanyPrice: ETariffVersions[];
}
