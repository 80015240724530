import { Avatar } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import UserIcon from '../../assets/svg/icons/user';
import { useAppSelector } from '../../hooks/hooks';
import { getHeaderTitle } from '../../store/selectors/header';
import ProfilePopover from '../profilePopover';
import NotificationsBlock from '../notificationsBlock';
import { getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { IProfilePermission } from '../../typings/profile';
import { IHeaderProps } from '../layout/types';

const Header: FC<IHeaderProps> = ({ profile }) => {
  const selectedSideBarTab = useAppSelector(getHeaderTitle);

  const roles = useMemo(() => profile?.roles?.map((r) => r.displayName).join(', '), [profile.roles]);

  const [profilePopoverIsOpen, setProfilePopoverIsOpen] = useState<boolean>(false);

  const handleOnChangeProfileIsOpen = useCallback((newOpen: boolean) => {
    setProfilePopoverIsOpen(newOpen);
  }, []);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.dispatcherPanel)) as IProfilePermission;

  return (
    <div className="header">
      <div className="header__container">
        <span className="header__title">{selectedSideBarTab}</span>
        <div className="header__nav">
          <span className="header__userinfo">
            {profile.lastName} {profile.firstName} ({profile.email}) <br />
            <span className="header__userinfo-roles"> {roles} </span>
          </span>
          {permissions?.edit && <NotificationsBlock />}
          <ProfilePopover open={profilePopoverIsOpen} onOpenChange={handleOnChangeProfileIsOpen}>
            <Avatar alt="User avatar" icon={<UserIcon />} className="header__avatar" />
          </ProfilePopover>
        </div>
      </div>
    </div>
  );
};

export default Header;
