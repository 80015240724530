import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  flatsCallBlocksUrl,
  objectBuildingSectionEditFlatsUrl,
  securityDeckFlatUpdateUrl,
} from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, InputType } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { IGroupEditModal } from './types';
import { getRequest, putRequest } from '../../../../api';
import Checkbox from '../../../ui/checkbox';
import { ISystemSection } from '../../../../typings/systems/section';
import SingleDatePicker from '../../../ui/singleDatePicker';
import { dateFormatOnlyTime } from '../../../../constants/date';
import Message from '../../../message';

const GroupEditModal: FC<IGroupEditModal> = (props) => {
  const {
    systemId = '',
    buildingId = '',
    sections = [],
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    isFiveThousandth,
    isSecurityDecks,
  } = props;

  const [selectedSection, setSelectedSection] = useState<ISystemSection | null>(null);
  const [callBlock, setCallBlock] = useState<string>('');
  const [isDirectCall, setIsDirectCall] = useState(true);
  const [isNotificationCallBack, setIsNotificationCallBack] = useState(true);
  const [isVideo, setIsVideo] = useState(true);
  const [isBlocking, setIsBlocking] = useState(true);
  const [isPhysicalCallsAvailable, setIsPhysicalCallsAvailable] = useState(true);
  const [isCallback, setIsCallback] = useState(true);
  const [flatNumberFrom, setFlatNumberFrom] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [flatNumberTo, setFlatNumberTo] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });

  const [isRedirectEnabled, setIsRedirectEnabled] = useState(false);
  const [nightModeEnd, setNightModeEnd] = useState<string | null>(null);
  const [nightModeStart, setNightModeStart] = useState<string | null>(null);

  const [generalError, setGeneralError] = useState('');
  const [timeError, setTimeError] = useState(false);

  const { sendRequest, loading } = useApi(putRequest);

  const {
    data: callBlocks,
    sendRequest: getCallBlocks,
    loading: callBlocksLoading,
  } = useApi<{ id: string; name: string }[]>(getRequest);

  useEffect(() => {
    if (timeError) setTimeError(false);
  }, [nightModeEnd, nightModeStart]);

  const onGetCallBlocks = useCallback(
    (id = selectedSection?.id) => {
      getCallBlocks(flatsCallBlocksUrl(systemId, id?.toString()));
    },
    [getCallBlocks, selectedSection, systemId]
  );

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) => {
      setter({
        value,
        status: InputStatus.normal,
        errorText: '',
      });
      setGeneralError('');
    },
    []
  );

  const resetCheckboxes = useCallback(() => {
    setIsDirectCall(true);
    setIsNotificationCallBack(true);
    setIsVideo(true);
    setIsBlocking(true);
    setIsPhysicalCallsAvailable(true);
    setIsCallback(true);
    setIsRedirectEnabled(false);
  }, []);

  const resetInputs = useCallback(() => {
    handleOnChangeInput(setFlatNumberFrom, '');
    handleOnChangeInput(setFlatNumberTo, '');
    setNightModeEnd(null);
    setNightModeStart(null);
  }, [handleOnChangeInput]);

  const handleOnChangeSection = useCallback(
    (sectionId: string | number) => {
      const findSection = sections.find((item) => item.id === sectionId);
      if (findSection) {
        setSelectedSection(findSection);
        setFlatNumberFrom({ ...flatNumberFrom, value: findSection.minRange.toString() });
        setFlatNumberTo({ ...flatNumberTo, value: findSection.maxRange.toString() });
      }
      setCallBlock('');
      onGetCallBlocks(sectionId.toString());
    },
    [flatNumberFrom, flatNumberTo, onGetCallBlocks, sections]
  );

  useEffect(() => {
    if (isOpen && sections && sections.length) {
      handleOnChangeSection(sections[0].id);
    }
  }, [isOpen]);

  const validateSequentialInputs = useCallback(
    (errorText = '') => {
      let isValid = true;
      if (!flatNumberFrom.value) {
        setFlatNumberFrom({ ...flatNumberFrom, status: InputStatus.error, errorText });
        isValid = false;
      }
      if (!flatNumberTo.value) {
        setFlatNumberTo({ ...flatNumberTo, status: InputStatus.error, errorText });
        isValid = false;
      }
      if (
        isValid &&
        (Number(flatNumberFrom.value) < Number(selectedSection?.minRange) ||
          Number(flatNumberTo.value) > Number(selectedSection?.maxRange))
      ) {
        setGeneralError('Диапазон квартир должен быть внутри выбранного подъезда');
        setFlatNumberFrom({
          ...flatNumberFrom,
          status:
            Number(flatNumberFrom.value) < Number(selectedSection?.minRange) ? InputStatus.error : InputStatus.normal,
        });
        setFlatNumberTo({
          ...flatNumberTo,
          status:
            Number(flatNumberTo.value) > Number(selectedSection?.maxRange) ? InputStatus.error : InputStatus.normal,
        });
        isValid = false;
      }
      return isValid;
    },
    [flatNumberFrom, flatNumberTo, selectedSection?.maxRange, selectedSection?.minRange]
  );

  const handleOnClickAddButton = useCallback(async () => {
    if (selectedSection) {
      if (validateSequentialInputs('Поле должно быть заполнено')) {
        let resError;
        if (isSecurityDecks)
          resError = await sendRequest(securityDeckFlatUpdateUrl(buildingId, selectedSection.id), {
            minRange: Number(flatNumberFrom.value),
            maxRange: Number(flatNumberTo.value),
            nightModeStart,
            nightModeEnd,
            isRedirectEnabled,
          });
        else
          resError = await sendRequest(objectBuildingSectionEditFlatsUrl(buildingId, selectedSection.id), {
            minRange: Number(flatNumberFrom.value),
            maxRange: Number(flatNumberTo.value),
            isDirectCall,
            isVideo,
            isBlocking: isFiveThousandth ? false : isBlocking,
            isBlocked: isFiveThousandth ? isBlocking : false,
            isPhysicalCallsAvailable,
            isNotificationCallBack,
            callBlockId: callBlock || null,
            isCallback,
          });
        if (resError?.response?.data) {
          setTimeError(true);
          return;
        }
      } else {
        return;
      }
      onOk();
      resetInputs();
      resetCheckboxes();
      setCallBlock('');
    }
  }, [
    buildingId,
    callBlock,
    flatNumberFrom.value,
    flatNumberTo.value,
    isBlocking,
    isCallback,
    isDirectCall,
    isFiveThousandth,
    isNotificationCallBack,
    isPhysicalCallsAvailable,
    isRedirectEnabled,
    isSecurityDecks,
    isVideo,
    nightModeEnd,
    nightModeStart,
    onOk,
    resetCheckboxes,
    resetInputs,
    selectedSection,
    sendRequest,
    validateSequentialInputs,
  ]);

  const handleOnClose = useCallback(() => {
    onCancel();
    resetInputs();
    resetCheckboxes();
    setCallBlock('');
    setNightModeStart(null);
    setNightModeEnd(null);
  }, [onCancel, resetCheckboxes, resetInputs]);

  const callBlockOptions = useMemo(
    () =>
      callBlocks?.map<ISelectOption>((item) => ({
        value: item.id || '',
        title: item.name || '',
      })) || [],
    [callBlocks]
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Групповое редактирование"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      footer={
        <div className="add-flats-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose} disabled={loading}>
            Отмена
          </Button>
          <Button
            disabled={!isFiveThousandth && !callBlock}
            size={ButtonSize.small}
            onClick={handleOnClickAddButton}
            loading={loading}
          >
            Применить
          </Button>
        </div>
      }
    >
      <div className="add-flats-modal">
        <Select
          title="Подъезд"
          containerClassName="add-flats-modal__field-wrapper"
          onChange={handleOnChangeSection}
          value={selectedSection?.id}
          options={[
            ...sections
              .filter((item) => item.flatCount !== 0)
              .map<ISelectOption>((section) => ({ value: section.id, title: section.name })),
          ]}
          disabled={loading}
        />
        <div className="add-flats-modal__field-wrapper">
          <span className="add-flats-modal__title">Диапазон квартир</span>
          <div className="add-flats-modal__input-wrapper">
            <Input
              placeholder="с"
              value={flatNumberFrom.value}
              onChange={(value) => handleOnChangeInput(setFlatNumberFrom, value)}
              status={flatNumberFrom.status}
              containerClassName="add-flats-modal__input"
              errorText={flatNumberFrom.errorText}
              maxLength={5}
              inputType={InputType.numbers}
              disabled={loading}
            />
            <Input
              placeholder="по"
              value={flatNumberTo.value}
              onChange={(value) => handleOnChangeInput(setFlatNumberTo, value)}
              status={flatNumberTo.status}
              containerClassName="add-flats-modal__input"
              errorText={flatNumberTo.errorText}
              maxLength={5}
              inputType={InputType.numbers}
              disabled={loading}
            />
          </div>
          {generalError && <span className="add-flats-modal__error">{generalError}</span>}
          {!isFiveThousandth && (
            <Select
              title="Блок вызова"
              containerClassName="add-flats-modal__field-wrapper"
              onChange={(val) => setCallBlock(val.toString())}
              value={callBlock}
              options={callBlockOptions}
              disabled={loading || !selectedSection || callBlocksLoading}
              onClick={() => onGetCallBlocks()}
            />
          )}
          {isSecurityDecks ? (
            <>
              <div className="add-flats-modal__checkbox-list">
                <Checkbox label="Перенаправление" checked={isRedirectEnabled} onChange={setIsRedirectEnabled} />
              </div>
              <SingleDatePicker
                format={dateFormatOnlyTime}
                isDisabledStyle
                title="Время смены дня"
                type="time"
                placeholder="-выберите-"
                containerClassName="system-base-tab__picker"
                dateValue={nightModeStart ? moment(nightModeStart, 'HH:mm') : null}
                onChange={(date) => setNightModeStart(date?.format(dateFormatOnlyTime) || null)}
                isError={timeError}
              />
              <SingleDatePicker
                format={dateFormatOnlyTime}
                isDisabledStyle
                type="time"
                title="Время смены ночи"
                placeholder="-выберите-"
                containerClassName="system-base-tab__picker"
                dateValue={nightModeEnd ? moment(nightModeEnd, 'HH:mm') : null}
                onChange={(date) => setNightModeEnd(date?.format(dateFormatOnlyTime) || null)}
                isError={timeError}
              />
            </>
          ) : (
            <div className="add-flats-modal__checkbox-list">
              <Checkbox label="Прямой вызов" checked={isDirectCall} onChange={setIsDirectCall} />
              <Checkbox label="Отзвонка" checked={isNotificationCallBack} onChange={setIsNotificationCallBack} />
              {isFiveThousandth && <Checkbox label="Видео" checked={isVideo} onChange={setIsVideo} />}
              {isFiveThousandth && <Checkbox label="Блок" checked={isBlocking} onChange={setIsBlocking} />}
              {isFiveThousandth && <Checkbox label="Обратный вызов" checked={isCallback} onChange={setIsCallback} />}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GroupEditModal;
