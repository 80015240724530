import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../../../../ui/input';
import MaskMacAddressInput from '../../../../../ui/input/maskInput/maskMacAddressInput';
import { EFieldTypes, InputStatus, InputType } from '../../../../../ui/input/types';
import Select from '../../../../../ui/select';
import { ISelectOption } from '../../../../../ui/select/types';
import { ICameraOptions } from './types';
import { useApi } from '../../../../../../hooks/useApi';
import { deleteRequest, postRequest } from '../../../../../../api';
import {
  getObjectBaseUrl,
  hardwareCameraConnectUrl,
  hardwareCameraDisconnectUrl,
} from '../../../../../../constants/api';
import Button from '../../../../../ui/button';
import { ButtonType } from '../../../../../ui/button/types';
import { defaultCameraOptions } from '../../../config';
import Checkbox from '../../../../../ui/checkbox';
import { ISystemBase } from '../../../../../../typings/systems/base';
import { getSystem } from '../../../../../../api/systems';

const CameraOptions: FC<ICameraOptions> = (props) => {
  const {
    options = defaultCameraOptions,
    settings = null,
    onChange = () => {},
    rtspServers = [],
    videoServers = [],
    permissions = {},
    handleOnSave = () => false,
    closeConfirm = () => {},
    setConfirmData = () => {},
    requestData = () => {},
  } = props;

  const { sendRequest: deleteCameraRequest, loading: deleteCameraRequestLoading } = useApi(deleteRequest);

  const { sendRequest: downloadSettingsRequest, loading: downloadSettingsRequestLoading } = useApi(postRequest);

  const { sendRequest: getSystemData } = useApi<ISystemBase>(getSystem);

  const [isError, setIsError] = useState(false);

  const [isItvAllowed, setItvAllowed] = useState(false);

  const { systemId } = useParams();

  useEffect(() => {
    if (systemId) {
      getSystemData(getObjectBaseUrl(systemId)).then((response) => {
        setItvAllowed((response as ISystemBase).isItvEnabled);
      });
    }
  }, []);

  const onDeleteCamera = useCallback(() => {
    setConfirmData({
      isOpen: true,
      description: 'Вы действительно хотите отвязать оборудование? В таком случае сбросятся все параметры',
      buttons: [
        {
          label: 'Отвязать',
          type: ButtonType.primary,
          onClick: async () => {
            closeConfirm();
            await deleteCameraRequest(hardwareCameraDisconnectUrl(settings?.id || ''));
            await requestData();
          },
        },
        {
          label: 'Отмена',
          type: ButtonType.secondary,
          onClick: closeConfirm,
        },
      ],
    });
  }, [closeConfirm, deleteCameraRequest, requestData, setConfirmData, settings?.id]);

  const onDownloadParams = useCallback(async () => {
    if (await handleOnSave()) {
      const resError = (await downloadSettingsRequest(hardwareCameraConnectUrl(settings?.id || ''), settings)) as any;

      if (resError?.response?.data) {
        setIsError(true);
      } else {
        requestData();
        setIsError(false);
      }
    }
  }, [downloadSettingsRequest, handleOnSave, requestData, settings]);

  const rtpsServersData = useMemo<ISelectOption[]>(
    () =>
      rtspServers
        ? rtspServers.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name,
          }))
        : [],
    [rtspServers]
  );

  const videoServersData = useMemo<ISelectOption[]>(
    () =>
      videoServers
        ? videoServers.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.name,
          }))
        : [],
    [videoServers]
  );

  const camIp = useMemo(
    () => rtspServers?.find((item) => item.id === options?.rtspServerId.value)?.apiUrl || '',
    [options?.rtspServerId.value, rtspServers]
  );

  const videoServerIp = useMemo(
    () => videoServers?.find((item) => item.id === options?.videoServerId.value)?.address || '',
    [options?.videoServerId.value, videoServers]
  );

  const isFull = useMemo(
    () => options.mac.value && options.rtspServerId.value,
    [options.mac.value, options.rtspServerId.value]
  );

  return (
    <div className="cam-options">
      <div className="cam-options__content">
        <div className="cam-options__cam-options-grid-row">
          <Select
            value={options.rtspServerId.value || ''}
            onChange={(e: string | number) => onChange(e, 'rtspServerId')}
            isError={options.rtspServerId.status === InputStatus.error}
            options={rtpsServersData}
            title="Rtsp-сервер"
            disabled={!permissions.edit}
            isRequired
          />
          <Input title="URL для rtsp" value={camIp} inputType={InputType.text} isDisabledStyle disabled />
        </div>
        <div className="cam-options__cam-options-grid-row">
          <Select
            value={options.videoServerId.value || ''}
            onChange={(e: string | number) => onChange(e, 'videoServerId')}
            isError={options.videoServerId.status === InputStatus.error}
            options={videoServersData}
            title="Видео-сервер"
            disabled={!permissions.edit}
            isRequired
          />
          <Input title="URL для видео" value={videoServerIp} inputType={InputType.text} isDisabledStyle disabled />
        </div>
        <div className="cam-options__cam-options-grid-row">
          <Input
            title="Логин доступа к камере"
            placeholder="Логин"
            textInfo="Если логин не задан, то берется значение по умолчанию"
            maxLength={100}
            inputType={InputType.text}
            disabled={!permissions.edit}
            value={options.innerLogin.value || ''}
            onChange={(e: string) => onChange(e, 'innerLogin')}
            status={options.innerLogin.status}
            errorText={options.innerLogin.errorText}
          />
          <Input
            title="Пароль доступа к камере"
            placeholder="Пароль"
            textInfo="Если пароль не задан, то берется значение по умолчанию"
            fieldType={EFieldTypes.password}
            maxLength={100}
            inputType={InputType.text}
            disabled={!permissions.edit}
            value={options.innerPassword.value || ''}
            onChange={(e: string) => onChange(e, 'innerPassword')}
            status={options.innerPassword.status}
            errorText={options.innerPassword.errorText}
          />
        </div>
        <div className="cam-options__cam-options-grid-row cam-options__cam-options-grid-row">
          <MaskMacAddressInput
            title="Mac-адрес"
            placeholder="Mac-адрес"
            isRequired
            disabled={!permissions.edit}
            value={options.mac.value || ''}
            onChange={(e: string) => onChange(e, 'mac')}
            status={options.mac.status}
            errorText={options.mac.errorText}
          />
          <Input
            title="Название для Панели диспетчера"
            placeholder="Название для Панели диспетчера"
            textInfo="Это название будет использоваться в Панели диспетчера, при выводе окна с видеопотоком камеры"
            maxLength={100}
            inputType={InputType.text}
            disabled={!permissions.edit}
            value={options.nameDispatcherPanel.value || ''}
            onChange={(e: string) => onChange(e, 'nameDispatcherPanel')}
            status={options.nameDispatcherPanel.status}
            errorText={options.nameDispatcherPanel.errorText}
          />
          {(settings?.externalId || isItvAllowed) && (
            <div className="cam-options__cam-options-grid-row cam-options__cam-options-grid-row">
              <Checkbox
                label="Использование системы ITV"
                checked={options.isItvEnabled.value}
                valueKey="isItvEnabled"
                onChange={(e: boolean) => onChange(e, 'isItvEnabled')}
                disabled={!permissions.edit}
              />
            </div>
          )}
        </div>
        <div className="cam-options__cam-options-grid-row cam-options__cam-options-grid-row">
          {settings?.externalId ? (
            <Button
              type={ButtonType.secondary}
              onClick={onDeleteCamera}
              disabled={!permissions.edit || deleteCameraRequestLoading}
            >
              Отвязать оборудование
            </Button>
          ) : (
            <Button
              type={ButtonType.outline}
              onClick={onDownloadParams}
              disabled={!permissions.edit || downloadSettingsRequestLoading || !isFull}
            >
              Привязать оборудование
            </Button>
          )}
        </div>
      </div>
      {!isFull && !settings?.externalId && !isError && (
        <div className="cam-options__warning">
          Для получения параметров камеры, заполните поля: mac-адрес, rtsp-сервер, логин доступа к камере, пароль
          доступа к камере
        </div>
      )}
      {isError && (
        <div className="cam-options__error">
          Камера с такими данными не найдена. Пожалуйста, проверьте вводимые параметры или попробуйте позже.
        </div>
      )}
    </div>
  );
};

export default CameraOptions;
