import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { InputStatus } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import { INonUniqueSerialNumberModal } from './types';
import { ESchemaModals, INonUniqueSerialNumberData } from '../types';
import { deviceNameLength } from '../../../../constants/limit';
import Scrollbar from '../../../ui/scrollbar';
import { IAccessPoint, ITreeNode } from '../../../../typings/treeNode';
import SerialNumberInput from '../../../ui/input/serialNumberInput';

const NonUniqueSerialNumberModal: FC<INonUniqueSerialNumberModal> = (props) => {
  const {
    type,
    serialNumbersData,
    isOpen = false,
    onOk = () => {},
    onCancel = () => {},
    editableSchema,
    setSchemas,
  } = props;

  const [serialNumbersEditData, setSerialNumbersEditData] = useState<INonUniqueSerialNumberData[]>([]);

  useEffect(() => {
    setSerialNumbersEditData(serialNumbersData);
  }, [serialNumbersData]);

  const nonUniqueSerialNumbersDictionary = useMemo(
    () =>
      serialNumbersEditData
        ? new Map(serialNumbersEditData.map((n: INonUniqueSerialNumberData) => [n.id, n.serialNumber]))
        : new Map(),
    [serialNumbersEditData]
  );

  const replaceFunction = useCallback((schemaData: ITreeNode[], dictionary: Map<string | undefined, string>) => {
    for (let j = 0; j < schemaData.length; j++) {
      const schemaItem = schemaData[j];
      if (schemaItem.accessPoints && schemaItem.accessPoints.length) {
        for (let i = 0; i < schemaItem.accessPoints.length; i++) {
          const accessPoint: IAccessPoint = schemaItem.accessPoints[i];
          if (accessPoint.inputDevice) {
            const inputDeviceId = accessPoint.inputDevice.id;
            if (accessPoint.inputDevice && dictionary.has(inputDeviceId)) {
              accessPoint.inputDevice.serialNumber = dictionary.get(inputDeviceId);
            }
          }

          if (accessPoint.outputDevice) {
            const outputDeviceId = accessPoint.outputDevice.id;
            if (accessPoint.outputDevice && dictionary.has(outputDeviceId)) {
              accessPoint.outputDevice.serialNumber = dictionary.get(outputDeviceId);
            }
          }
        }
      }
      if (schemaItem.childItems) {
        replaceFunction(schemaItem.childItems, dictionary);
      }
    }
  }, []);

  const handleChange = useCallback(
    (setter: (value: any) => void, newValue: string, index: number, keyName: string): void => {
      const editedData = serialNumbersEditData.map((_item: INonUniqueSerialNumberData, _index: number) =>
        index === _index ? { ..._item, [keyName]: newValue } : _item
      );
      return setter(editedData);
    },
    [serialNumbersEditData]
  );

  const handleOnClickOkButton = useCallback(() => {
    replaceFunction(editableSchema.current, nonUniqueSerialNumbersDictionary);
    setSchemas(editableSchema.current);
    onOk();
  }, [editableSchema, nonUniqueSerialNumbersDictionary, onOk, replaceFunction, setSchemas]);

  const renderUniqueSerials = useCallback(
    () => (
      <Scrollbar>
        <div className="serial-numbers-modal__hardwares">
          {serialNumbersEditData.map((uniqueSerial: INonUniqueSerialNumberData, index: number) => (
            <div key={index} className="serial-numbers-modal__hardware">
              <div className="serial-numbers-modal__hardware-title">
                {uniqueSerial.address}, {uniqueSerial.accessPointName}
              </div>
              <div className="serial-numbers-modal__hardware-inputs">
                <Input
                  title="Название оборудования"
                  maxLength={deviceNameLength}
                  value={uniqueSerial.deviceName}
                  status={InputStatus.normal}
                  onChange={(value) => handleChange(setSerialNumbersEditData, value, index, 'deviceName')}
                  disabled
                  isDisabledStyle
                />
                <SerialNumberInput
                  title="Серийный номер"
                  maxLength={20}
                  value={uniqueSerial.serialNumber}
                  status={InputStatus.normal}
                  onChange={(value) => handleChange(setSerialNumbersEditData, value, index, 'serialNumber')}
                  placeholder="Серийный номер"
                />
              </div>
              {uniqueSerial.companyName && (
                <div className="serial-numbers-modal__hardware-inputs">
                  <Input title="Организация" value={uniqueSerial.companyName} disabled isDisabledStyle />
                  <div className="input">
                    <span className="input__title-container">
                      <span className="input__title">Объект</span>
                    </span>
                    <div className="link-container">
                      <a
                        id={uniqueSerial.id}
                        href={`/systems/${uniqueSerial.objectId}?tabId=basic`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {uniqueSerial.objectName}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Scrollbar>
    ),
    [handleChange, serialNumbersEditData]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={
        type === ESchemaModals.nonUniqueSerialNumber
          ? 'Замените неуникальные серийные номера'
          : 'Замените некорректные серийные номера'
      }
      onCancel={onCancel}
      width={700}
      wrapClassName="serial-numbers-modal"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnClickOkButton} size={ButtonSize.small}>
            Сохранить
          </Button>
          <Button type={ButtonType.secondary} onClick={onCancel} size={ButtonSize.small}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="serial-numbers-modal__content">
        <div className="serial-numbers-modal__notification-info">
          Серийный номер должен быть уникальным в рамках всей платформы
        </div>
        {serialNumbersEditData && renderUniqueSerials()}
      </div>
    </Modal>
  );
};

export default NonUniqueSerialNumberModal;
