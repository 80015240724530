import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';
import { parsePhoneNumber } from 'react-phone-number-input';
import InfoIcon from '../../../../../assets/svg/icons/info';
import { ONLY_NUMBERS_DOT_REGEX } from '../../../../../constants/regex';
import Tooltip from '../../../tooltip';
import { IMaskInput } from '../types';
import { InputStatus } from '../../types';

const CoordinateInput: FC<IMaskInput> = (props) => {
  const {
    title,
    placeholder = '',
    value = '',
    status = InputStatus.normal,
    disabled = false,
    onChange = () => {},
    containerClassName = '',
    inputClassName = '',
    titleClassName = '',
    errorText = '',
    errorTextClassName = '',
    description = '',
    onFocus = () => {},
    onBlur = () => {},
    isRequired = false,
    isDisabledStyle = false,
    textInfo,
  } = props;

  const handleOnChange = useCallback(
    (currentValue = '', event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(parsePhoneNumber(currentValue)?.number || currentValue, event);
    },
    [onChange]
  );

  const inputProps = useMemo(
    () => ({
      className: classNames('input__input default-scrollbar-override', inputClassName, {
        'input__mask-error ant-input-status-error': status === InputStatus.error,
        'input__input_default-disabled': isDisabledStyle,
      }),
      placeholder,
      value,
      status,
      disabled,
      onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleOnChange(event.target.value, event),
      onFocus,
      onBlur,
      alwaysShowMask: false,
      maskPlaceholder: '',
    }),
    [disabled, handleOnChange, inputClassName, isDisabledStyle, onBlur, onFocus, placeholder, status, value]
  );

  return (
    <div className={classNames('input', containerClassName)}>
      {title && (
        <span className={classNames('input__title', titleClassName)}>
          {title}
          {isRequired && <span className="input__title-required">*</span>}
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="input__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      <InputMask
        {...inputProps}
        mask={[
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ',',
          ' ',
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
      />
      {description && <div className="input-description">{description}</div>}
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default CoordinateInput;
